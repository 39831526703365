<template>
<div>
<div class="footer">
    <section class="margin-center content">
        <section class="content-info">
            <div class="el-image content-info__img">
                <img src="../assets/image/43.png" alt="" class="el-image__inner">
                <!----></div> 
                <p class="content-info__phone">029-87211111</p> 
                <p class="content-info__tips">悦木互动服务热线 | 周一至周五 24小时</p>
        </section> 
        <section class="content-contact content-contact1">
            <h1 class="font-bold title content-contact__title">联系我们</h1> 
            <p class="content-contact__item">
                媒体联络 Caroline@sxoppo.com
            </p>
            <p class="content-contact__item">
                人力招聘 lixin@sxoppp.com 
            </p>
            <p class="content-contact__item">
                投诉通道 nana@sxoppo.com
            </p>
           </section> 
           <section class="content-contact content-contact1">
                            <h1 class="font-bold title content-contact__title">联系地址</h1>
                            <p class="content-contact__item">西安 碑林区柏树林街道综合大厦5F</p>
                            <p class="content-contact__item">深圳 南山区南头古城春景路7号</p>
                            <p class="content-contact__item">北京 朝阳区东十里堡路未来时大厦10F</p>
                        </section> 
            <section class="content-concern">
                <h1 class="font-bold title content-concern__title">关注我们</h1>
                <div>
                    <div class="el-image">
                     <img src="../assets/image/44.png" class="el-image__inner">
                     <p>关注公众号</p>
                    <p>了解更多</p>
                    </div>
                    <div class="el-image">
                     <img src="../assets/image/90.png" class="el-image__inner">
                     <p>新浪微博</p>
                    <p>了解更多</p>
                    </div>
                </div>
                
            </section>
    </section>
</div>
  <p class="copy">Copyright © 2020 西安悦目互动信息科技有限责任公司 版权所有 <a class="copy" href="https://beian.miit.gov.cn/" style="margin-left:10px" target="_blank">陕ICP备2022003039号-1</a></p>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.copy {
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    text-align: center;
    color: rgba(63,63,63,.2);
}
  .footer {
      height: 414px !important;
      position: relative;
      border-top: 1px solid #f0ecec;
      .content {
        height: 363px;
        padding: 50px 0;
        display: flex;
       justify-content:space-between;
      }
      .content-info{
        text-align: left;
        width: 323px;
       .content-info__img{
       
        width: 164px;
         img {
             width: 100%;
             height: 100%;
         }
        }
        .content-info__phone {
            margin-top: 29px;
            font-size: 32px;
            line-height: 24px;
            color: #3f3f3f;
        }
        .content-info__tips {
            margin-top: 16px;
            font-size:12px;
            line-height:12px;
            color: rgba(63,63,63,.6);
        }
      }
      .content-contact {
          width: 324px;
          
          .content-contact__item {
              margin-bottom:  16PX;
                font-size: 14PX;
                color: #3f3f3f;
          }
      }
      .content-contact2 {
          width: 306PX;
        margin-top: 72PX;
      }
      .weui-flex__item {
          display: flex;
      }
      .content-product__logo {
          width: 70px;
            height: 70px;
            margin-right: 32px
      }
      .content-product__logo-hover {
          color: #000;
         position: relative;
      }
  }
  .title {
              font-size: 24PX;
              text-align: left;
                line-height: 32PX;
                margin:0 0 40px 0;
                font-variation-settings: "wght" 900;
          }
 .el-image img {
    width: 72px;
    height: 72px;
    margin-bottom: 8px
}
.content-concern {
    text-align: left;
}
.content-concern p {
    width: 80px;
    text-indent: -6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: #3f3f3f;
    color: rgba(63,63,63,.6);
}
</style>
